import React, {useState} from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { loginUser, logoutUser, loginSampleUser, loginUserWithMfa, loginMsrpUser, loginUserBypassMfa } from '../../../redux/actions/userActions';

export const LoginContainer = ({ history, sample, triggerMfa, msrp, onLoginSuccess, loginUserWithMfa, loginUserBypassMfa, loginUser, loginSampleUser, loginMsrpUser, logoutUser, ...props }) => {

  const [loading, setLoading] = useState(false);

  const onLogin = async (username, password, token, success, failure, isClientMfaBypassEnabled, randomGeneratedId, firstName, lastName, company, email, phone, phoneExt, captcha) => {

    try {
      setLoading(true);
      const languageUsed = history.location.pathname.split("/")[1];

      if (sample) {
        const status = await loginSampleUser(username, password, firstName, lastName, company, email, phone, phoneExt, captcha);
        if (status) {
          success();
        } else {
          failure();
        }

      } else if (msrp) {
        const status = await loginMsrpUser(username, password, captcha);
        if (status) {
          success();
        } else {
          failure();
        }

      } else if (triggerMfa) {
        const status = await loginUserWithMfa(username, token, isClientMfaBypassEnabled, randomGeneratedId, languageUsed);
        if (status) {
          success();
          const currentUrl = "/" + history.location.pathname.split("/")[1];
          history.push(`${currentUrl}`);
        } else {
          const currentUrl = history.location.pathname.substring(0, history.location.pathname.lastIndexOf("/"));
          triggerMfa = false;
          await onLogoutWrongToken(success, failure);
          history.push(`${currentUrl}`, { mfaError: true });
        }
        
      } else {
        const loginResult = await loginUser(username, password, languageUsed);
        const isLoginPageValid = loginResult.isLoginPageValid;
        if(isLoginPageValid === false) {
          if(username === "MSRP") {
            history.push(`${history.location.pathname}/msrp`);
          } else if(username === "SampleMerch") {
            history.push(`${history.location.pathname}/sample`);
          } else {
            await onLogoutWrongToken(success, failure);
          }
        } else {
          const loginStatus = loginResult.status;
          if (loginStatus) {
            const resetPassword = loginResult.user.resetPassword === "true";
            if(resetPassword) {
              const currentUrl = history.location.pathname;
              history.push(`${currentUrl}`, { expiredPasswordError: true });
            } else {
              const isMFABypassValid = loginResult.user.isMFABypassValid === "true";
  
              if(isMFABypassValid) {
                const status = await loginUserBypassMfa(username, languageUsed);
                if(status) {
                  success();
              
                  const currentUrl = "/" + history.location.pathname.split("/")[1];
                  history.push(`${currentUrl}`);
                } else {
                  await onLogoutWrongToken(success, failure);
                }
              } else {
                 // before showing MFA page
                const isClientMfaBypassEnabled = loginResult.user.isClientMfaBypassEnabled === "true";
                const mfaBypassPeriod = loginResult.user.mfaBypassPeriod;
                const randomGeneratedId = loginResult.user.randomId;
    
                onLoginSuccess(username, isClientMfaBypassEnabled, mfaBypassPeriod, randomGeneratedId);
                history.push(`${history.location.pathname}/token`);
              }
            }
          } else {
            failure();
          }
        }
      }
    } catch (error) {
      console.error('Error during login:', error);
    } finally {
      setLoading(false);
    }
  };

  const onLogoutWrongToken = async (success, failure) => {
    try {
      setLoading(true);
      const status = await logoutUser();
      if (!status) failure();
    } catch (error) {
      console.error('Error during logout wrong token:', error);
    } finally {
      setLoading(false);
    }
  };

  const onLogout = async (success, failure) => {
    try {
      setLoading(true);
      const status = await logoutUser();
      if (status) {
        success();
      } else {
        failure();
      }
    } catch (error) {
      console.error('Error during logout:', error);
    } finally {
      setLoading(false);
    }
  };

  const childrenWithProps = React.Children.map(props.children, child =>
    React.cloneElement(child, { onLogin, onLogout, sample, loading })
  );
  return <>{childrenWithProps}</>;
};

LoginContainer.propTypes = {
  loginUser: propTypes.func.isRequired,
  loginSampleUser: propTypes.func.isRequired,
  loginMsrpUser: propTypes.func.isRequired,
  logoutUser: propTypes.func.isRequired,
  loginUserWithMfa: propTypes.func.isRequired,
  loginUserBypassMfa: propTypes.func.isRequired
};

const mapDispatchToProps = {
  loginUser,
  loginSampleUser,
  loginMsrpUser,
  logoutUser,
  loginUserWithMfa,
  loginUserBypassMfa,
};

export default connect(
  null,
  mapDispatchToProps
)(LoginContainer);
