import React from 'react';
import { Link } from 'react-router-dom';
import { fonts } from '../../../../../defaults/styles';
import { lsStyled as styled } from '../../../../../tools/helpers/lsStyled';
import { withLocalize } from 'react-localize-redux';

const ProductImage = styled.img`
  max-width: 100%;
`;

export const OrderItemBody = withLocalize(({ order, lang, translate }) => {
  const t = (tag) => `orders.${tag}`;

  // Fetching correct text based on status ID from the backend
  const statusToLexicon = statusId => {
    const map = {
      '1': 'process',
      '2': 'shipped',
      '3': 'invoiced',
      '4': 'invoiced',
    };
    return map[statusId] ? translate(t(`detail.${map[statusId]}`)) : <></>;
  };

  return (
    <div className="card-body" style={{ fontSize: '15px' }}>
      <div className="row">
        {/* Order status */}
        <div
          className="col-lg-12 text-left"
          style={{
            fontSize: '17px',
            fontWeight: 'bold',
            marginBottom: '20px',
          }}
        >
          {statusToLexicon(order.statusId)}
        </div>

        {/* Left content */}
        <div className="col-lg-7">
          <div className="row">
            {/* Product image */}
            <div
              className="col-lg-4 text-center"
              style={{ position: 'relative' }}
            >
              <ProductImage
                className="product-img"
                src={order.imageUrl}
                alt="Product"
              />
            </div>

            {/* Order detail */}
            <div className="col-lg-8 text-left" style={{ marginBottom: fonts.sizes.largeText }}>
              <div className="row">
                {/* Header */}
                <div
                  className="col-lg-12"
                  style={{
                    fontSize: '17px',
                    fontWeight: 'bold',
                    marginBottom: '20px',
                  }}
                >
                  {translate(t('description'))}
                </div>
              </div>

              <div className="col-lg-12" style={{ position: 'relative' }}>
                {/* Sku */}
                <Link to={`/${lang}/product/${order.sku}`}>{order.sku}</Link>
                <br />

                {/* Product name */}
                <Link to={`/${lang}/product/${order.sku}`}>
                  {order.productName}
                </Link>
                <br />

                {/* Brand */}
                <span>
                  {translate(t('label.by'))}
                  &nbsp;
                  <Link
                    to={`/${lang}/catalog?brand=${order.brandId}`}
                  >
                    {order.brand}
                  </Link>
                </span>
                <br />

                {/* Quantity */}
                <span>
                  {translate(t('label.quantity'))}
                  &nbsp;
                  {order.quantity}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Right content */}
        <div className="col-lg-5 text-left" style={{ marginBottom: fonts.sizes.largeText }}>
          <div className="row">
            {/* Header */}
            <div
              className="col-lg-12"
              style={{
                fontSize: '17px',
                fontWeight: 'bold',
                marginBottom: '20px',
              }}
            >
              {translate(t('shippingAddress'))}
            </div>
          </div>

          {/* Shipping address */}
          <div className="col-lg-12" style={{ position: 'relative' }}>
            {/* Name */}
            {order.shippingName}
            <br />
            {/* Company */}
            {order.company}
            <br />
            {/* Other Name */}
            {order.otherName}
            <br />
            {/* Address line one */}
            {order.shippingLineOne}
            <br />
            {/* Address line two if exists */}
            {order.shippingLineTwo && (
              <>
                {order.shippingLineTwo}
                <br />
              </>
            )}
            {/* City */}
            {order.shippingCity}
            ,&nbsp;
            {/* Province */}
            {order.shippingProvince}
            ,&nbsp;
            {/* Country */}
            {order.shippingCountry}
            <br />
            {/* Postal code */}
            {order.shippingPostalCode}
            <br />
            {/* Phone Number */}
            {order.phoneNumber}
            <br />
            {/* Care Of */}
            {order.careOf}
            <br />
            {/* Customization Flag */}
            {order.customizationFlag}
          </div>
        </div>
      </div>
    </div>
  );
});
