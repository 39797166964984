import { LSFetch } from '../helpers'

export const getOrders = params => {
  return LSFetch.get('/order', { params })
}

export const getCart = (language) => {
  return LSFetch.get(`/order/cart`, { params: { language }});
}

export const deleteCartItem = (sku, language) => {
  return LSFetch.del(`/order/cart/item/${sku}`, { queryString: { language }});
}

export const addCartItem = (sku) => {
  return LSFetch.post(`/order/cart/item/${sku}`);
}
export const updateCartItem = (sku, quantity, language) => {
  return LSFetch.put(`/order/cart/item/${sku}`, { queryString: { quantity, language }});
}

export const updateCartInfo = (cart, language) => {
  return LSFetch.put(`/order/cart/info`, { 
    body: JSON.stringify(cart), 
    headers: { 'Content-Type': 'application/json;charset=UTF-8' }, 
    queryString: { language }
  });
}

export const updateCartAddress = (address, language) => {
  return LSFetch.put(`/order/cart/address`, {
    body: JSON.stringify(address),
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    queryString: { language }
  });
}

export const submitCart = (cart) => {
  return LSFetch.post(`/order/cart`);
}

export const sendConfirmation = (subject, email, content, language) => {
  return LSFetch.post(`/order/cart/confirmation`, {
    body: content,
    headers: { 'Content-Type': 'text/plain' },
    queryString: { subject, email, language }
  })
}