import React from 'react';
import FooterLink from './FooterLink';
import { lsStyled as styled } from './../../../../../../tools/helpers/lsStyled';
import {
  colors,
  spacing,
  typography,
} from './../../../../../../defaults/styles';
import { Translate, withLocalize } from 'react-localize-redux';

// == Styling == //
const FooterTitle = styled.h4`
  ${typography.h4};
  color: ${colors.white.normal};
  margin-bottom: ${spacing.medium}px;
`;
// == /Styling == //
const FooterLinksRight = ({activeLanguage}) => {
  const t = (tag) => `footer.services.${tag}`;
  const lang =
  activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';
  const quickGuideUrl = lang == 'fr' ? "/documents/Guide%20rapide.pdf" : "documents/Quick%20Start%20Guide.pdf";
  const detailedInstructionsUrl = lang == 'fr' ? "/documents/Instructions%20d%C3%A9taill%C3%A9es.pdf" : "/documents/Detailed%20Instructions.pdf"

  return (
    <>
      <FooterTitle>{<Translate id={t('title')} />}</FooterTitle>
      <FooterLink url="/services-rewards" text={<Translate id={t('rewards')} />} />
      <FooterLink
        url="/services-fulfilment"
        text={<Translate id={t('directToConsumer')} />}
      />
      <FooterLink url="/services-gifting" text={<Translate id={t('gifting')} />} />
      <FooterLink url="/brands" text={<Translate id={t('brands')} />} />
      {/* <FooterLink url="/services" text={<Translate id={t('services')} />} /> */}
      <FooterLink url="/catalog" text={<Translate id={t('catalogue')} />} />
      <FooterLink url="/brand-partners" text={<Translate id={t('brandPartners')} />} />
      <FooterLink url={quickGuideUrl} text={<Translate id={t('quickGuide')} />} />
      <FooterLink url={detailedInstructionsUrl} text={<Translate id={t('detailedInstructions')} />} />
    </>
  );
};

export default withLocalize(FooterLinksRight);
