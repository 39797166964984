import * as types from './types';
import { authenticate } from '../../tools/api';
import { beginApiCall, apiCallError } from './apiStatusActions';
import {
  removeAuthAndSession,
  setCookieTillMidnight,
  setCookie,
  SECRET_KEY,
  AUTH_TOKEN,
  USER_EMAIL,
  USER_INFO
} from '../../tools/helpers/cookieHelper';
import jwt from "jsonwebtoken";


export const loadStatusSuccess = status => {
  return { type: types.LOAD_USER_STATUS_SUCCESS, status };
};

export const loadStatus = () => {
  return dispatch => {
    dispatch(beginApiCall());
    return authenticate
      .getStatus()
      .then(status => {
        dispatch(loadStatusSuccess(status));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
};

export const loginUserSuccess = user => {
  return { type: types.LOGIN_USER_SUCCESS, user };
};

export const loginFailure = error => ({
  type: types.LOGIN_USER_FAILURE,
  error
});

export const loginTokenSuccess = token => ({
  type: types.LOGIN_TOKEN_SUCCESS,
  token
});

export const loginUser = (username, password, languageUsed) => {
  if(username === "MSRP" || username === "SampleMerch") {
    return dispatch => {
      dispatch(beginApiCall());
      const loginResult = {
        isLoginPageValid: false,
      }
      return loginResult;
    }
  } else {
    return dispatch => {
      dispatch(beginApiCall());
      return authenticate
        .login(username, password, languageUsed)
        .then(({ status, json: user }) => {
          if (status !== 202) return false;
  
          const loginResult = {
            status: true,
            user: user
          }
        
          return loginResult;
        })
        .catch(error => {
          dispatch(apiCallError(error));
          throw error;
        });
    };
  }
};

export const loginUserBypassMfa = (username, password, languageUsed) => {
  return dispatch => {
    dispatch(beginApiCall());
    return authenticate
      .loginBypassMfa(username, password, languageUsed)
      .then(({ status, json: user }) => {
        if (status !== 202) return false;

        dispatch(loginUserSuccess({ ...user, loggedIn: true, checked: true }));

        const loginResult = {
          status: true,
          user: user
        }
      
        return loginResult;
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
};

export const autoLoginUser = (authToken, languageUsed) => {
  return async dispatch => {
    dispatch(beginApiCall());
    try {
      const decodedToken = jwt.verify(authToken, SECRET_KEY);
      const { username, password } = decodedToken;

      const response = await authenticate.login(username, password, languageUsed);

      const { status, json: user } = response;

      if (status === 202) {
        //Re-login
        dispatch(loginUserSuccess({ ...user, loggedIn: true, checked: true }));
        return true;
      } else {
        dispatch(loginFailure('Auto login user with username and password failed'));
        return false;
      }
    } catch (error) {
      dispatch(apiCallError(error));
      throw error;
    }
  };
};

export const loginUserWithMfa = (username, token, isClientMfaBypassEnabled, randomGeneratedId, languageUsed) => {
  return dispatch => {
    dispatch(beginApiCall());
    return authenticate
      .loginWithMfa(username, token, isClientMfaBypassEnabled, randomGeneratedId, languageUsed)
      .then(response => {
        if(response.status !== 202) {
          return false;
        }
        const user = response.json;
        dispatch(loginUserSuccess({ ...user, loggedIn: true, checked: true }));
        return true;
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
};

export const loginSampleUser = (username, password, firstName, lastName, company, email, phone, phoneExt, captcha) => {
  return dispatch => {
    dispatch(beginApiCall());
    return authenticate
      .loginSample(username, password, firstName, lastName, company, email, phone, phoneExt, captcha)
      .then(( { status, json: user }) => {
        if (status !== 202) return false;

        dispatch(loginUserSuccess({ ...user, loggedIn: true, checked: true }));
        return true;
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      })
  }
}

export const loginMsrpUser = (username, password, captcha) => {
  return dispatch => {
    dispatch(beginApiCall());
    return authenticate
      .loginMsrp(username, password, captcha)
      .then(( { status, json: user }) => {
        if (status !== 202) return false;

        dispatch(loginUserSuccess({ ...user, loggedIn: true, checked: true }));
        return true;
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      })
  }
}

const logoutUserSuccess = () => ({
  type: types.LOGOUT_USER_SUCCESS,
});

export const logoutUser = () => {
  return dispatch => {
    dispatch(beginApiCall());
    return authenticate
      .logout()
      .then(({ status, json: user }) => {
        if (status !== 202) return false;
        removeAuthAndSession();

        dispatch(logoutUserSuccess());
        return true;
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
};

const userInfoSuccess = (userInfo) => ({
  type: types.USER_INFO_SUCCESS, userInfo
});

export const loadInfo = () => {
  return dispatch => {
    dispatch(beginApiCall());
    return authenticate
      .info()
      .then(({ json: userInfo }) => {
        dispatch(userInfoSuccess(userInfo));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  }
}

const userParentInfoSuccess = (userParentInfo) => ({
  type: types.USER_PARENT_INFO_SUCCESS, userParentInfo
});

export const loadParentInfo = () => {
  return dispatch => {
    dispatch(beginApiCall());
    return authenticate
      .parentInfo()
      .then(({ json: userParentInfo }) => {
        dispatch(userParentInfoSuccess(userParentInfo));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  }
}

const vendorAgreementSuccess = (vendorAgreement) => ({
  type: types.VENDOR_AGREEMENT_SUCCESS, vendorAgreement
});

export const loadVendorAgreement = (token) => {
  return dispatch => {
    dispatch(beginApiCall());
    return authenticate
      .vendorAgreement(token)
      .then(({ json: vendorAgreement }) => {
        dispatch(vendorAgreementSuccess(vendorAgreement));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
};