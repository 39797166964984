import * as types from './types'
import { beginApiCall, apiCallError } from './apiStatusActions'
import { order } from '../../tools/api'

export const loadCartSuccess = cart => {
    return { type: types.LOAD_CART_SUCCESS, cart }
}

export const loadCart = (language, filter) => {
    return dispatch => {
        dispatch(beginApiCall());
        return order
            .getCart(language)
            .then(({ json }) => {
                const cart = filter ? filter(json) : json;
                cart.hasAccessToAPI = json.hasAccessToAPI;
                dispatch(loadCartSuccess(cart));
            })
            .catch(error => {
                dispatch(apiCallError(error))
                throw error
            })
    }
}

export const removeFromCart = (sku, language) => {
    return dispatch => {
        dispatch(beginApiCall());
        return order
            .deleteCartItem(sku, language)
            .then(({ json }) => {
                dispatch(loadCartSuccess(json));
            })
            .catch(error => {;
                dispatch(apiCallError(error))
                throw error
            })
    }
}

export const addCartItem = (sku, ) => {
    return dispatch => {
        dispatch(beginApiCall());
        return order
            .addCartItem(sku)
            .then(({ json }) => {
                dispatch(loadCartSuccess(json));
            })
            .catch(error => {
                dispatch(apiCallError(error));
                throw error;
            })
    }
}

export const updateCartItem = (sku, quantity, language) => {
    return dispatch => {
        dispatch(beginApiCall());
        return order
            .updateCartItem(sku, quantity, language)
            .then(({ json }) => {
                dispatch(loadCartSuccess(json));
            })
            .catch(error => {
                dispatch(apiCallError(error));
                throw error;
            })
    }
}

export const updateCartInfo = (cart, language) => {
    return dispatch => {
        dispatch(beginApiCall());
        return order
            .updateCartInfo(cart, language)
            .then(({ json }) => {
                dispatch(loadCartSuccess(json));
            })
            .catch(error => {
                dispatch(apiCallError(error));
                throw error;
            })
    }
}

export const updateCartAddress = (address, language) => {
    return dispatch => {
        dispatch(beginApiCall());
        return order
            .updateCartAddress(address, language)
            .then(({ json }) => {
                dispatch(loadCartSuccess(json));
            })
            .catch(error => {
                dispatch(apiCallError(error));
                throw error;
            })
    }
}

export const submitCart = (cart, callback) => {
    // return dispatch => {
    //     dispatch(beginApiCall());
    //     return callback(cart);
    // }
    return dispatch => {
        dispatch(beginApiCall());
        return order
            .submitCart(cart)
            .then(({json}) => {
                callback(json);
            })
            .catch(error => {
                dispatch(apiCallError(error));
                throw error;
            })
    }
}

export const sendConfirmation = (subject, email, content, language) => {
    return dispatch => {
        dispatch(beginApiCall());
        return order
            .sendConfirmation(subject, email, content, language)
            .then(() => {})
            .catch(error => {
                dispatch(apiCallError(error));
                throw error;
            })
    }
}
